import Checkbox from "@/Components/Checkbox";
import InputError from "@/Components/InputError";
import InputLabel from "@/Components/InputLabel";
import PrimaryButton from "@/Components/PrimaryButton";
import TextInput from "@/Components/TextInput";
import GuestLayout from "@/Layouts/GuestLayout";
import { Head, useForm, router } from "@inertiajs/react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { useLoginMutation } from "@/services/auth.service.js";
import { CONSTANTS } from "@/utils/index.js";

export default function Login({ status }) {
    const [login, loginResult] = useLoginMutation();
    const [showPassword, setShowPassword] = useState(false);

    const { data, setData, errors } = useForm({
        email: "",
        password: "",
        remember: "",
    });

    function onHandleChange(event) {
        setData(
            event.target.name,
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value,
        );
    }

    async function submit(e) {
        e.preventDefault();
        const response = await login(data);
        if ("data" in response) {
            router.get(response.data.redirect_to);
            localStorage.setItem(CONSTANTS.AUTH_TOKEN, response.data.token);
            localStorage.setItem("pageTitle", "Login");
            await Swal.fire({
                title: "Success!",
                text: "You have been logged in successfully.",
                icon: "success",
                confirmButtonText: "Ok",
            });
            return;
        }
        if ("error" in response) {
            await Swal.fire({
                title: "Error!",
                text: response.error,
                icon: "error",
                confirmButtonText: "Ok",
            });
        }
    }

    return (
        <GuestLayout>
            <Head>
                <title>Log in</title>
                <meta
                    name="Login"
                    content="Login for admin page of gssi system"
                />
                <link
                    rel="icon"
                    type="image/svg+xml"
                    href="/assets/image/logo/favicon.png"
                />
            </Head>
            {status && (
                <div className="mb-4 font-medium text-sm text-green-600">
                    {status}
                </div>
            )}

            <form onSubmit={submit}>
                <div>
                    <InputLabel forInput="email" value="Email" />
                    <TextInput
                        type="text"
                        name="email"
                        value={data.email}
                        className="mt-1 block w-full"
                        autoComplete="username"
                        isFocused={true}
                        handleChange={onHandleChange}
                        placeholder="Username"
                    />
                    <InputError message={errors.email} className="mt-2" />
                </div>

                <div className="mt-4">
                    <InputLabel forInput="password" value="Password" />
                    <TextInput
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={data.password}
                        className="mt-1 block w-full"
                        autoComplete="current-password"
                        handleChange={onHandleChange}
                        placeholder="Password"
                        iconRight={{
                            onClick: () => setShowPassword((prev) => !prev),
                            icon: showPassword ? (
                                <Visibility />
                            ) : (
                                <VisibilityOff />
                            ),
                        }}
                    />
                    <InputError message={errors.password} className="mt-2" />
                </div>
                <div className="block mt-4">
                    {/* biome-ignore lint/a11y/noLabelWithoutControl: <explanation> */}
                    <label className="flex items-center">
                        <Checkbox
                            name="remember"
                            value={data.remember}
                            handleChange={onHandleChange}
                        />
                        <span className="ml-2 text-sm text-gray-600">
                            Remember me
                        </span>
                    </label>
                </div>
                <div className="flex items-center justify-end mt-4">
                    {/* {canResetPassword && (
                        <Link
                            href={route('password.request')}
                            className="underline text-sm text-gray-600 hover:text-gray-900"
                        >
                            Forgot your password?
                        </Link>
                    )} */}
                    <PrimaryButton
                        className="ml-4"
                        processing={loginResult.isLoading}
                    >
                        Log in
                    </PrimaryButton>
                </div>
            </form>
        </GuestLayout>
    );
}
